import React from 'react'
import { Container, Text, useBreakpoints } from 'candour'
import StickyContainer from '../StickyContainer'

export default () => {
  const { small } = useBreakpoints()

  return (
    <StickyContainer
      backgroundColor='sky-blue'
      backgroundImage='url("/products/lunaflow/multiple-phones.png")'
      backgroundPosition='100% 100%'
      backgroundPositionCenter={small}
      backgroundSize='50%'
      backgroundSizeContain={small}
      width='100%'
      paddingTop={small ? 2 : 0}
    >
      <Container maxWidth={30}>
        <Container fontWeightBold lineHeight={1.5}>
          Project status
        </Container>
        <Container lineHeight={1.5}>
          Pre-release
        </Container>
        <Container paddingTop={2} lineHeight={1.5}>
          <Text level5={small}>
            <Text fontWeightBold displayInline level5={small}>Challenge</Text> – According to the best-selling author
            Miranda Gray, our ancestors saw the menstrual cycle as a
            source of creative, spiritual, sexual, emotional, and physical
            energies. It empowered women to renew themselves each month and form
            positive habits.
          </Text>

          <Text paddingTop={2} level5={small}>
            Currently there is no easy way to track how a menstrual cycle,
            lunar cycle and emotions are connected. Understanding
            the patterns behind women’s cyclic nature helps women lead a
            creative cycle-empowered life and make better decisions.
          </Text>

          <Text paddingTop={2} level5={small}>
            The core question I raised was "How to help
            women understand more about themselves and make the
            learning process easy and 100% stress-free?"
          </Text>
        </Container>
      </Container>
    </StickyContainer>
  )
}
