import React, { useEffect } from 'react'
import { Container } from 'candour'
import Meta from './Meta'
import Splash from './Splash'
import Projects from './Projects'

export default () => {
  useEffect(() => {
    window.analytics.page()
  })

  return (
    <Container>
      <Meta />
      <Splash />
      <Projects />
    </Container>
  )
}
