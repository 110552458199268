import React from 'react'
import { Container, Heading, Button, useBreakpoints } from 'candour'

const FooterButton = (props) => (
  <Button component='a' borderRadius='8px' textAlignCenter marginRight displayBlock minWidth={10} border='2px solid white' {...props} />
)

export default () => {
  const { small } = useBreakpoints()

  return (
    <Container minHeight='100vh' padding2 padding1={small} paddingTop={4} paddingBottom={4} backgroundColorPink colorWhite>
      <Heading level={1} fontWeightBold paddingTop4>
        Let’s get in touch
      </Heading>

      <Container paddingTop2 maxWidth={30}>
        <Container level={4} level5={small} paddingTop>
          I am a marketer & product designer with background in growth and social media marketing.
        </Container>

        <Container paddingTop2 displayFlex alignItemsCenter>
          <FooterButton href='mailto:vaida.lekaviciute@gmail.com' backgroundColorWhite colorPink>
            vaida.lekaviciute@gmail.com
          </FooterButton>
          <FooterButton href='/VaidaLekaviciute.pdf' target='_blank' backgroundColorTransparent colorWhite>
            Professional CV
          </FooterButton>
        </Container>
      </Container>
    </Container>
  )
}
