import React from 'react'
import { Container, useBreakpoints } from 'candour'
import Navbar from '../../Navbar'
import Blobs from './Blobs'

export default () => {
  const { small } = useBreakpoints()

  return (
    <Container
      backgroundColorDarkBlue
      minHeight='110vh'
      colorWhite
      positionRelative
    >
      <Blobs />
      <Container
        positionAbsolute
        width='100%'
        padding2
        padding1={small}
      >
        <Navbar />

        <Container
          level={1}
          fontSize4={small}
          fontWeightBold
          maxWidth={50}
          paddingTop='25vh'
        >
          Lunaflow - moonly menstrual tracker
        </Container>
      </Container>
    </Container>
  )
}
