import React from 'react'
import { Container, Text, Link, Button } from 'candour'
import { Link as RouterLink } from 'react-router-dom'

const onClick = (e) => {
  e.preventDefault()
  window.scrollTo(0, document.body.scrollHeight)
}

export default () => (
  <Container displayFlex justifyContentSpaceBetween alignItemsCenter>
    <Link component={RouterLink} to='/'>
      <Text fontWeightBold colorWhite>
        Vaida Lekavičiūtė
      </Text>
      <Text colorWhite>
        Marketing & Product Design
      </Text>
    </Link>

    <Container>
      <Button
        onClick={onClick}
        level={7}
        borderRadius='9999px'
        paddingTop={0.7}
        paddingBottom={0.7}
        backgroundColor='transparent'
        fontWeight600
        colorWhite
      >
        Get in touch
      </Button>
    </Container>
  </Container>
)
