import React from 'react'
import { Container, Text, useBreakpoints } from 'candour'
import StickyContainer from '../StickyContainer'

export default () => {
  const { small } = useBreakpoints()

  return (
    <StickyContainer
      backgroundColor='night-sky-blue'
      backgroundImage='url("/products/lunaflow/three-phones.png")'
      backgroundPosition='calc(100% - 20px) 70px'
      backgroundPositionCenter={small}
      smallMinHeight={30}
      backgroundSize={small ? '80%' : 'contain'}
      paddingBottom={small ? 4 : 0}
      marginBottom={0}
    >
      <Container maxWidth={30}>
        <Container paddingTop={4} lineHeight={1.5}>
          <Text level5={small}>
            <Text fontWeightBold displayInline level5={small}>Solution</Text> – I came
            up with Lunaflow that has 3 core components:
            a Moon calendar, menstrual tracker and a notebook to track
            user’s emotions.
          </Text>

          <Text paddingTop={2} level5={small}>
            For the graphic expression I opted for a modern look with a
            sprinkle of coziness. The typeface is clean and breezy. The color
            theme of shades of blue not only calms but also represents
            the magical night time. Together with the fun illustration
            of a rising Moon the app gives a sparkle of joy that
            women need in their lifes while taking care of their
            cycle health.  
          </Text>

          <Text paddingTop={2} level5={small}>
            Million thanks to Domas Bitvinskas who
            helped me build the prototype.
          </Text>
        </Container>
      </Container>
    </StickyContainer>
  )
}
