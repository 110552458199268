import React from 'react'
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'

import Meta from './Meta'
import Landing from './Landing'
import Lunaflow from './Lunaflow'
import Footer from './Footer'

export default () => (
  <Router>
    <ScrollToTop>
      <Meta />

      <Route path='/' exact component={Landing} />
      <Route path='/lunaflow' exact component={Lunaflow} />

      <Footer />
    </ScrollToTop>
  </Router>
)
