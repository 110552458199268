import React from 'react'
import { Container, Text, useBreakpoints } from 'candour'
import Project from './Project'

export default () => {
  const { small } = useBreakpoints()

  return (
    <Container>
      <Project
        name='Lunaflow'
        participation='Concept / Design / UI'
        time='2019 Q1'
        title='Track Your Cycle & Chill'
        backgroundImage='url("/products/lunaflow.png")'
        backgroundColor='violet'
        url='/lunaflow'
      >
        <Text level5={small}>
          Lunaflow helps young women keep track of their cycles and feel
          excited about it. No need to overly complicate it - write down how
          you’re doing and have your cycle day at hand.
        </Text>
        <Text level5={small} paddingTop={2}>
          Simple. Bright. Lovable.
        </Text>
      </Project>
      {false && <Project
        name='GraphQL.jobs'
        participation='Design / UI / Logo'
        time='2019 Q1 - Now'
        title='Modern GraphQL jobs board'
        url='https://graphql.jobs'
        backgroundImage='url("/products/graphqljobs.png")'
        backgroundColor='pink'
        width='70%'
        left='30%'
      >
        <Text level5={small}>
          GraphQL.jobs is a curated job board for software developers that want
          to work in the most forward-thinking companies and the most hip query
          language.
        </Text>
      </Project>}
      <Project
        name='Kurkim'
        participation='Concept / Design / UI / Logo'
        time='2018 Q2'
        title='Platform for Creatives'
        url='https://kurkim.netlify.com'
        backgroundImage='url("/products/kurkim.png")'
        backgroundColor='blue'
        backgroundPosition='100% 100%'
        marginRight={-2}
        width='60%'
        left='40%'
      >
        <Text level5={small}>
          Kurkim is a platform that connects creative professionals
          with businesses. It features a curated job board, social profiles of
          creatives & businesses and daily inspiration.
        </Text>
      </Project>
      <Project
        name='Candour'
        participation='Design / UI / Illustration / Logo'
        time='2018 Q4'
        url='https://candour.pro'
        title='Next-gen React Design Abstraction Layer'
        backgroundImage='url("/products/candour.png")'
        backgroundColor='black'
        width='60%'
        left='40%'
        backgroundPosition='80% 100%'
      >
        <Text level5={small}>
          Candour is an abstraction layer to build context-aware design systems.
          It enables web developers to build complex UI systems with less effort.
        </Text>
      </Project>
    </Container>
  )
}
