import React from 'react'
import { Container, useBreakpoints } from 'candour'

export default ({
  name,
  participation,
  title,
  backgroundImage,
  backgroundColor,
  children,
  url,
  time,
  paddingTop = 2,
  paddingBottom = 4,
  minHeight = '100vh',
  smallMinHeight = 20,
  ...rest,
}) => {
  const { small } = useBreakpoints()

  return (
    <Container
      minHeight={minHeight}
      colorWhite
      zIndex={1}
      positionRelative
      backgroundColor={backgroundColor}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
    >
      <Container
        className='sticky'
        backgroundImage={backgroundImage}
        minHeight={small ? smallMinHeight : '100vh'}
        backgroundPosition='center'
        backgroundSize='contain'
        backgroundRepeat='no-repeat'
        top={0}
        positionStatic={small}
        width='75%'
        left='25%'
        zIndex={-1}
        marginBottom={2}
        {...rest}
        {...(small ? { width: '100%' } : {})}
      />
      <Container
        paddingBottom30={!small}
        marginTop={small ? 0 : '-80vh'}
        paddingLeft2
        paddingRight2
        paddingLeft1={small}
        paddingRight1={small}
      >
        {children}
      </Container>
    </Container>
  )
}
