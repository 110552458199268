import React from 'react'
import { Container } from 'candour'

export default () => (
  <>
    <Container
      positionAbsolute
      top={0}
      overflowHidden
      width='100%'
    >
      <Container
        backgroundColorNightSkyBlue
        width={40}
        height={40}
        borderRadius={9999}
        transform='scale(2, 1.2)'
        marginLeft='85vw'
        marginTop={-20}
      />
      <Container
        backgroundColorSkyBlue
        width={20}
        height={20}
        borderRadius={9999}
        transform='scale(1.7, 1.5)'
        marginTop={-28}
        marginLeft='74vw'
        marginBottom={20}
      />
      <Container
        backgroundColorElectroGreen
        width={30}
        height={30}
        borderRadius={9999}
        transform='scale(1.8, 1)'
        marginTop={-42}
        marginLeft='85vw'
        marginBottom={20}
      />
    </Container>

    <Container
      positionAbsolute
      left={0}
      bottom={0}
      overflowHidden
      width='100%'
    >
      <Container
        backgroundColorWhite
        boxShadow='0px 0px 20px 0px #fff'
        width={8}
        height={8}
        borderRadius={9999}
        marginTop={10}
        marginBottom={-13}
        marginLeft={15}
      />

      <Container
        backgroundColorNightSkyBlue
        width={40}
        height={40}
        borderRadius={9999}
        transform='scale(2, 1)'
        marginBottom={-40}
        marginLeft={-30}
      />
      <Container
        backgroundColorSkyBlue
        width={30}
        height={30}
        borderRadius={9999}
        transform='scale(2, 1)'
        marginBottom={-25}
        marginLeft={-27}
      />
      <Container
        backgroundColorElectroGreen
        width={30}
        height={30}
        borderRadius={9999}
        transform='scale(2, 1)'
        marginBottom={-20}
        marginLeft={-20}
      />
    </Container>
  </>
)
