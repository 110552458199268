import React from 'react'
import {
  Container,
  Heading,
  Text,
  Link,
  useBreakpoints,
} from 'candour'
import Navbar from './Navbar'

export default () => {
  const { small } = useBreakpoints()

  return (
    <Container
      padding2
      padding1={small}
      minHeight='120vh'
      backgroundImage='url("/splash.jpg")'
      backgroundSize='100vh'
      backgroundRepeat='no-repeat'
      backgroundPosition='center right'
      backgroundPositionCenter={small}
      backgroundColorBlack
      displayFlex
      flexDirectionColumn
      justifyContentSpaceBetween
    >
      <Navbar />
      <Container
        colorWhite
        displayFlex
        alignItemsCenter
        paddingTop={2}
        paddingBottom={6}
        maxWidth={50}
        margin={small && '0 auto'}
      >
        <Container width='100%'>
          <Text fontSize={2.5} fontWeightBold>
            Something you’ve spent years
            creating may be destroyed overnight.
          </Text>
          <Heading fontSize={1.5} fontSize3={small} paddingTop>
            Create anyway.
          </Heading>
        </Container>
      </Container>
      <Container displayFlex justifyContentFlexEnd>
        <Link colorTransparentWhite href='https://www.facebook.com/k.gureviciute/' target='_blank'>
          Photo by Kotryna Gurevičiūtė
        </Link>
      </Container>
    </Container>
  )
}
