import React from 'react'
import ReactDOM from 'react-dom'
import { CandourProvider } from 'candour'
import CandourNormalize from 'candour-normalize'
import fluidSteps from 'candour-fluid-steps'
import colors from 'candour-colors'

import themeColors from './theme/colors'
import './theme/index.css'
import App from './components/App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <CandourProvider
    converters={[fluidSteps(), colors(themeColors)]}
    breakpoints={{ small: '768px' }}
  >
    <CandourNormalize />

    <App />
  </CandourProvider>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
