import React, { useEffect } from 'react'
import { Container } from 'candour'
import Splash from './Splash'
import Challenge from './Challenge'
import Approach from './Approach'
import Solution from './Solution'

export default () => {
  useEffect(() => {
    window.analytics.page()
  })

  return (
    <Container>
      <Splash />
      <Challenge />
      <Approach />
      <Solution />
    </Container>
  )
}
