import React from 'react'
import { Container, Heading, Text, Button, useBreakpoints } from 'candour'
import { Link } from 'react-router-dom'
import StickyContainer from '../StickyContainer'

const isLocal = (url) => url && url[0] === '/'

export default ({
  name,
  participation,
  title,
  backgroundColor,
  children,
  url,
  time,
  ...rest,
}) => {
  const { small } = useBreakpoints()
  const localButtonProps = {
    component: Link,
    to: url,
  }
  const externalButtonProps = {
    component: 'a',
    a: url,
    target: '_blank',
  }
  const buttonProps = isLocal(url) ? localButtonProps : externalButtonProps

  return (
    <StickyContainer
      backgroundColor={backgroundColor}
      {...rest}
    >
      <Container displayFlex alignItemsCenter paddingBottom={0.5}>
        <Text fontWeightBold>
          {name}
        </Text>
        <Text paddingLeft paddingRight>—</Text>
        <Text>
          {participation}
        </Text>
      </Container>
      <Text fontWeight300 level={7}>
        {time}
      </Text>
      <Heading level={2} fontWeightBold maxWidth={30} paddingTop={2}>
        {title}
      </Heading>

      <Container maxWidth={18} paddingTop4 paddingTop2={small}>
        {children}
      </Container>

      <Button
        disabled={!url}
        href={url}
        displayInlineBlock
        backgroundColorWhite
        backgroundColorTransparentWhite={!url}
        color={backgroundColor}
        borderRadius='8px'
        marginTop2
        minWidth10
        textAlignCenter
        {...buttonProps}
      >
        {url ? `View ${name}` : 'Pre-release'}
      </Button>
    </StickyContainer>
  )
}
