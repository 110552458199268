export default {
  violet: '#3200B8',
  pink: '#A6046C',
  blue: '#137FD1',
  green: '#13D199',
  'transparent-white': 'hsla(0, 0%, 100%, 0.4)',
  'dark-blue': '#0C002B',
  'electro-green': '#48D9C1',
  'sky-blue': '#029FD8',
  'night-sky-blue': '#06518C',
  'transparent': 'hsla(0, 0%, 100%, 0)',
  'transparent-black': 'hsla(0, 0%, 0%, 0.5)',
}
