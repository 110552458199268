import React from 'react'
import { Container, Text, useBreakpoints } from 'candour'
import StickyContainer from '../StickyContainer'

export default () => {
  const { small } = useBreakpoints()

  return (
    <StickyContainer
      backgroundColor='electro-green'
      backgroundImage={small ? 'url("/products/lunaflow/hands.jpg")' : 'linear-gradient(to right, transparent, hsla(0, 0%, 0%, 0.8)), url("/products/lunaflow/hands.jpg")'}
      width='100%'
      backgroundSize='cover'
      paddingTop={0}
      paddingBottom={small ? 4 : 0}
      marginBottom={0}
      minHeight={small ? 30 : '100vh'}
    >
      <Container maxWidth={30} marginLeftAuto={!small}>
        <Container paddingTop={small ? 4 : 20} lineHeight={1.5}>
          <Text level5={small}>
            <Text fontWeightBold displayInline level5={small}>Approach</Text> – Most of
            menstrual trackers on the market today are overly complicated
            with way too many functions and ads that lead to an
            unpleasent user experience.
          </Text>

          <Text paddingTop={2} level5={small}>
            Lunaflow’s vision is to provide a human-first experience
            that is both simple and joyful.
          </Text>
        </Container>
      </Container>
    </StickyContainer>
  )
}
